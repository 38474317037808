.App {
  text-align: center;
  color: #3d3d3d;
  background-color: bisque;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* This ensures the Nav is at the top and the rest of the content flows below */
.Nav {
  width: 100%;
}

.content-container {
  display: flex;
  flex-direction: row; /* Ensure they are side by side */
}

.vertical-nav {
  width: 300px; /* Adjust based on your preference */
}

.dashboard-content {
  flex-grow: 1;
  padding: 20px; /* Adds some spacing inside the dashboard content area */
}

.dev-banner {
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
  padding: 2px 0; /* Reduce padding to keep it thin */
  font-size: 12px; /* Smaller text */
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 15px; /* Make it even thinner */
  display: flex;
  align-items: flex-start; /* Align text to the top */
  justify-content: center; /* Keep text centered horizontally */
}