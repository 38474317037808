.p-menubar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 64px;
}

/* Add padding for logo */
.p-menubar .p-menubar-start {
    padding-left: 1rem;
}

/* Ensure content clears the fixed menubar */
.main-content {
    padding-top: 64px;
}